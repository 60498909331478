import { paramsFromObject } from "@/assets/js/utils/functions";
import { TransactionSearchParams } from "./interfaces";
import { request } from "./utils";

const GetStoreCredits = () => {
  return request("users/credits", "get");
};

const GetStoreReferrals = () => {
  return request("users/referrals", "get");
};

const GetReferralRewards = () => {
  return request("users/referrals/rewards", "get");
};

const CheckReferralCodeValidity = (data: { code: string }) => {
  return request(`users/referrals/check-validity?code=${data.code}`, "get");
};

const GetCreditsTransactions = (data: TransactionSearchParams) => {
  const params = paramsFromObject(data);
  return request(`users/credits/transactions?${params}`, "get");
};

const GetReferralsLeaderboard = () => {
  return request(`users/referrals/leaderboard`, "get");
};

const ConvertCreditsToCash = (data: { amount: number }) => {
  return request("users/credits/convert-to-cash", "post", { data });
};

export {
  GetStoreCredits,
  GetStoreReferrals,
  GetReferralRewards,
  CheckReferralCodeValidity,
  GetCreditsTransactions,
  GetReferralsLeaderboard,
  ConvertCreditsToCash,
};
