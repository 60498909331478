export enum SESSION_STATUSES {
  ONGOING = "ONGOING",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  CONFIRMATION_PENDING = "CONFIRMATION_PENDING",
  REJECTED = "ORDER_REJECTED",
  UNCONFIRMED = "UNCONFIRMED", // TIMEOUT
  UNCOMPLETED = "UNCOMPLETED", // SESSION TIMEDOUT
  DROPPED_OFF = "DROPPED_OFF", // ENDED SESSION
  COMPLETED = "COMPLETED",
}

export interface ChowbotSession {
  id: string;
  customer: {
    name: string;
    phone: string;
    id: string;
  };
  created_at: string;
  ended_at?: string;
  status: SESSION_STATUSES;
  order?: string;
  from: string;
  to: string;
  is_billable?: boolean;
}
