import { paramsFromObject } from "@/assets/js/utils/functions";
import {
  LoginParams,
  RequestPasswordResetParams,
  ResetPasswordParams,
  SignUpParams,
  UpdatePasswordParams,
  UpdateProfileParams,
  SendVerificationTokensParams,
  VerifyTokensParams,
  RegisterPushNotificationsParams,
} from "./interfaces/auth.interface";
import { request } from "./utils";

const Login = (data: LoginParams) => {
  return request("users/login", "post", { data });
};

const Register = (data: SignUpParams) => {
  const sourceAd = JSON.parse(localStorage?.getItem("sourceAd") ?? "{}"); //input ad params

  return request("users", "post", { data: { ...data, source_ad: sourceAd } });
};

const getCurrentSession = () => {
  return request(`users/me`, "get");
};

const GetNewToken = (data: { store?: string }) => {
  return request(`users/refresh-jwt_token/${data.store ?? ""}`, "get");
};

const UpdateProfile = (data: UpdateProfileParams) => {
  return request("users", "put", { data });
};

const UpdatePassword = (data: UpdatePasswordParams) => {
  return request("users/password", "put", { data });
};

const RequestPasswordReset = (data: RequestPasswordResetParams) => {
  return request("users/request-password-reset", "post", { data });
};

const ResetPassword = (data: ResetPasswordParams) => {
  return request("users/reset-password", "post", { data });
};

const SendVerificationTokens = (data: SendVerificationTokensParams) => {
  return request("users/send-verification-tokens", "post", { data });
};

const VerifyTokens = (data: VerifyTokensParams) => {
  return request("users/verify-tokens", "post", { data });
};

const UpdateLastLogin = () => {
  return request("users/update-last-login", "get");
};

const RegisterPushNotification = (data: RegisterPushNotificationsParams) => {
  return request("users/register-push-notification", "post", { data });
};

const RemovePushSubscription = (data: RegisterPushNotificationsParams) => {
  return request("users/remove-push-subscription", "delete", { data });
};

export {
  Login,
  Register,
  getCurrentSession,
  UpdateProfile,
  UpdatePassword,
  GetNewToken,
  RequestPasswordReset,
  ResetPassword,
  SendVerificationTokens,
  VerifyTokens,
  UpdateLastLogin,
  RegisterPushNotification,
  RemovePushSubscription,
};
