import "../assets/styles/index.scss";
import authContext from "../contexts/auth-context";
import { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import Router from "next/router";
import { useEffect } from "react";
import Head from "next/head";
import ErrorBoundary from "@/components/error-boundary";
import useSearchParams from "@/components/hooks/useSearchParams";
import { useLocalObject } from "@/components/hooks/useLocalState";

const progress = new ProgressBar({
  size: 2,
  color: "#EF940F",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const MyApp = ({ Component, pageProps }) => {
  const { utm_campaign, utm_source, utm_medium, gclid } = useSearchParams([
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "gclid",
  ]);
  const [soureAdStorage, setSourceAdStorage] = useLocalObject<{
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    gclid?: string;
  }>("sourceAd", {});

  useEffect(() => {
    const sourceAdObj = {};

    if (utm_campaign) sourceAdObj["utm_campaign"] = utm_campaign;
    if (utm_medium) sourceAdObj["utm_medium"] = utm_medium;
    if (utm_source) sourceAdObj["utm_source"] = utm_source;
    if (gclid) sourceAdObj["gclid"] = gclid;

    //prevents overwriting of localstorage data
    if (Object.values(sourceAdObj).filter((v) => !!v).length > 0) {
      setSourceAdStorage(sourceAdObj);
    }
  }, [utm_campaign, utm_source, utm_medium, gclid]);

  return (
    <ErrorBoundary>
      <authContext.Provider>
        <Head>
          {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" /> */}
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Head>
        <Component {...pageProps} />
        <Toaster position="bottom-right" reverseOrder={false} />
        {/* <ReactTooltip /> */}
      </authContext.Provider>
    </ErrorBoundary>
  );
};

export default MyApp;
