import React from "react";
import ErrorScreen from "./ui/error-screen";
import { AppBtn } from "./ui/buttons";
import { reloadPage } from "@/assets/js/utils/functions";
import { WHATSAPP_LINK } from "@/assets/js/utils/constants";

class ErrorBoundary extends React.Component<any, { hasError?: boolean }> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorScreen title="Oops! Something Broke." message="Our engineers have been notified please reload the page">
          <div className="flex items-center space-x-2.5 mt-5">
            <AppBtn onClick={reloadPage} size="md">
              Reload Page
            </AppBtn>
            <AppBtn href={WHATSAPP_LINK} size="md" color="neutral">
              Contact Support
            </AppBtn>
          </div>
        </ErrorScreen>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
