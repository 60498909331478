import classNames from "classnames";
import React from "react";

type IconType =
  | "edit"
  | "delete"
  | "link"
  | "download"
  | "copy"
  | "copy_text"
  | "route"
  | "refresh"
  | "cancel"
  | "hide"
  | "show"
  | "copy_doc"
  | "options"
  | "stack"
  | "add_circle"
  | "dollar_circle";

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon?: IconType;
  white?: boolean;
  grey?: boolean;
  smoke?: boolean;
  size?: "sm" | "md";
}

const RoundActionButton: React.FC<IProps> = ({
  className,
  children,
  icon,
  white = false,
  grey = true,
  smoke = false,
  type = "button",
  size = "md",
  ...props
}) => {
  const classes = classNames(
    "flex items-center justify-center text-black-placeholder rounded-full disabled:text-grey-muted group flex-shrink-0",
    className,
    {
      "bg-grey-fields-100": grey,
      "bg-white": white,
      "bg-grey-smoke": smoke,
      "w-9 h-9": size === "md",
      "w-7.5 h-7.5": size === "sm",
    }
  );

  return (
    <button className={classes} type={type} {...props}>
      {icon ? <RoundButtonIcons icon={icon} /> : children}
    </button>
  );
};

export const RoundButtonIcons: React.FC<{ icon: IconType }> = ({ icon }) => {
  const icons = {
    copy:
      // prettier-ignore
      <svg width="50%" viewBox="0 0 16 16" fill="none" >
        <path d="M8.70656 7.29333C10.2066 8.79334 10.2066 11.22 8.70656 12.7133C7.20656 14.2067 4.7799 14.2133 3.28656 12.7133C1.79323 11.2133 1.78656 8.78667 3.28656 7.29333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.05989 8.94004C5.49989 7.38004 5.49989 4.84671 7.05989 3.28005C8.61989 1.71338 11.1532 1.72005 12.7199 3.28005C14.2866 4.84005 14.2799 7.37338 12.7199 8.94004" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    link:
      // prettier-ignore
      <svg width="14" viewBox="0 0 16 16" fill="none" className="hover:text-primary-500">
        <path d="M8.70656 7.29333C10.2066 8.79334 10.2066 11.22 8.70656 12.7133C7.20656 14.2067 4.7799 14.2133 3.28656 12.7133C1.79323 11.2133 1.78656 8.78667 3.28656 7.29333" strokeWidth={1.2} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.05989 8.94004C5.49989 7.38004 5.49989 4.84671 7.05989 3.28005C8.61989 1.71338 11.1532 1.72005 12.7199 3.28005C14.2866 4.84005 14.2799 7.37338 12.7199 8.94004" strokeWidth={1.2} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    edit:
      // prettier-ignore
      <svg width="14" viewBox="0 0 16 16" fill="none" className="hover:text-primary-500">
        <path d="M3.69332 13.0137C3.28666 13.0137 2.90666 12.8737 2.63332 12.6137C2.28666 12.287 2.11999 11.7937 2.17999 11.2604L2.42666 9.10036C2.47332 8.69369 2.71999 8.15369 3.00666 7.86036L8.47999 2.06702C9.84666 0.620358 11.2733 0.580358 12.72 1.94703C14.1667 3.31369 14.2067 4.74036 12.84 6.18703L7.36666 11.9804C7.08666 12.2804 6.56666 12.5604 6.15999 12.627L4.01332 12.9937C3.89999 13.0004 3.79999 13.0137 3.69332 13.0137ZM10.62 1.94036C10.1067 1.94036 9.65999 2.26036 9.20666 2.74036L3.73332 8.54036C3.59999 8.68036 3.44666 9.01369 3.41999 9.20703L3.17332 11.367C3.14666 11.587 3.19999 11.767 3.31999 11.8804C3.43999 11.9937 3.61999 12.0337 3.83999 12.0004L5.98666 11.6337C6.17999 11.6004 6.49999 11.427 6.63332 11.287L12.1067 5.49369C12.9333 4.61369 13.2333 3.80036 12.0267 2.66703C11.4933 2.15369 11.0333 1.94036 10.62 1.94036Z" fill="currentColor" />
        <path d="M11.56 7.30023C11.5466 7.30023 11.5266 7.30023 11.5133 7.30023C9.4333 7.09356 7.75996 5.51356 7.43996 3.44689C7.39996 3.17356 7.58663 2.92023 7.85996 2.87356C8.1333 2.83356 8.38663 3.02023 8.4333 3.29356C8.68663 4.90689 9.9933 6.14689 11.62 6.30689C11.8933 6.33356 12.0933 6.58022 12.0666 6.85356C12.0333 7.10689 11.8133 7.30023 11.56 7.30023Z" fill="currentColor" />
        <path d="M14 15.167H2C1.72667 15.167 1.5 14.9403 1.5 14.667C1.5 14.3937 1.72667 14.167 2 14.167H14C14.2733 14.167 14.5 14.3937 14.5 14.667C14.5 14.9403 14.2733 15.167 14 15.167Z" fill="currentColor" />
      </svg>,
    delete:
      // prettier-ignore
      <svg width="14" viewBox="0 0 20 20" fill="none" className="hover:text-accent-red-500">
        <path d="M17.0142 5.45236C16.998 5.45236 16.9737 5.45236 16.9494 5.45236C12.6635 5.02296 8.38571 4.86093 4.14844 5.29032L2.49566 5.45236C2.15539 5.48477 1.85562 5.24171 1.82321 4.90144C1.7908 4.56116 2.03386 4.26949 2.36604 4.23708L4.01881 4.07505C8.329 3.63755 12.6959 3.80769 17.0709 4.23708C17.4031 4.26949 17.6461 4.56926 17.6137 4.90144C17.5894 5.21741 17.3221 5.45236 17.0142 5.45236Z" fill="currentColor" strokeWidth="1"/>
        <path d="M6.88754 4.63422C6.85513 4.63422 6.82272 4.63422 6.78221 4.62612C6.45814 4.56941 6.23129 4.25344 6.288 3.92936L6.46624 2.86802C6.59587 2.09024 6.77411 1.0127 8.66184 1.0127H10.7845C12.6804 1.0127 12.8586 2.13075 12.9801 2.87612L13.1584 3.92936C13.2151 4.26154 12.9882 4.57751 12.6642 4.62612C12.332 4.68283 12.016 4.45598 11.9674 4.13191L11.7892 3.07867C11.6757 2.37381 11.6514 2.23608 10.7926 2.23608H8.66994C7.81115 2.23608 7.79494 2.3495 7.67342 3.07057L7.48707 4.12381C7.43846 4.42358 7.1792 4.63422 6.88754 4.63422Z" fill="currentColor" strokeWidth="1"/>
        <path d="M12.3222 18.4319H7.12079C4.29325 18.4319 4.17982 16.8682 4.0907 15.6043L3.56408 7.44576C3.53977 7.11359 3.79903 6.82192 4.13121 6.79762C4.47149 6.78141 4.75505 7.03257 4.77936 7.36475L5.30598 15.5233C5.3951 16.7548 5.42751 17.2166 7.12079 17.2166H12.3222C14.0236 17.2166 14.056 16.7548 14.137 15.5233L14.6636 7.36475C14.6879 7.03257 14.9796 6.78141 15.3118 6.79762C15.6439 6.82192 15.9032 7.10549 15.8789 7.44576L15.3523 15.6043C15.2632 16.8682 15.1497 18.4319 12.3222 18.4319Z" fill="currentColor" strokeWidth="1"/>
        <path d="M11.0673 13.976H8.36936C8.03718 13.976 7.76172 13.7006 7.76172 13.3684C7.76172 13.0362 8.03718 12.7607 8.36936 12.7607H11.0673C11.3994 12.7607 11.6749 13.0362 11.6749 13.3684C11.6749 13.7006 11.3994 13.976 11.0673 13.976Z" fill="currentColor" strokeWidth="1"/>
        <path d="M11.7484 10.7348H7.69748C7.36531 10.7348 7.08984 10.4593 7.08984 10.1272C7.08984 9.79499 7.36531 9.51953 7.69748 9.51953H11.7484C12.0806 9.51953 12.356 9.79499 12.356 10.1272C12.356 10.4593 12.0806 10.7348 11.7484 10.7348Z" fill="currentColor" strokeWidth="1"/>
      </svg>,
    download:
      // prettier-ignore
      <svg width="50%" viewBox="0 0 14 14" fill="none" className="hover:text-primary-500">
        <path d="M7.08146 9.291L7.08146 1.26367" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.02539 7.33887L7.08139 9.29087L5.13739 7.33887" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.1699 4.41895H10.7919C12.1486 4.41895 13.2479 5.51828 13.2479 6.87561L13.2479 10.1316C13.2479 11.4849 12.1513 12.5816 10.7979 12.5816L3.37126 12.5816C2.01459 12.5816 0.914592 11.4816 0.914592 10.1249L0.914592 6.86828C0.914592 5.51561 2.01193 4.41895 3.36459 4.41895H3.99259" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </svg>,
    copy_text:
      // prettier-ignore
      <svg width="50%" viewBox="0 0 24 24" fill="none">
        <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    route:
      // prettier-ignore
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" >
        <path d="M7.04163 5.95833L11.4833 1.51666" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.9167 3.68331V1.08331H9.31665" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.95837 1.08331H4.87504C2.16671 1.08331 1.08337 2.16665 1.08337 4.87498V8.12498C1.08337 10.8333 2.16671 11.9166 4.87504 11.9166H8.12504C10.8334 11.9166 11.9167 10.8333 11.9167 8.12498V7.04165" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    refresh:
      // prettier-ignore
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" >
      <path d="M6.99984 12.8334C10.2215 12.8334 12.8332 10.2217 12.8332 7.00002C12.8332 3.77836 10.2215 1.16669 6.99984 1.16669C3.77818 1.16669 1.1665 3.77836 1.1665 7.00002C1.1665 10.2217 3.77818 12.8334 6.99984 12.8334Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.67236 8.46416C4.77736 8.63916 4.90569 8.8025 5.05153 8.94833C6.12486 10.0217 7.86902 10.0217 8.94819 8.94833C9.38569 8.51083 9.63653 7.95664 9.71819 7.39081" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.28174 6.60917C4.3634 6.0375 4.61424 5.48914 5.05174 5.05164C6.12508 3.97831 7.86924 3.97831 8.94841 5.05164C9.10007 5.20331 9.22257 5.36665 9.32757 5.53582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.56152 10.0217V8.46417H6.11901" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.43835 3.97833V5.53582H7.88086" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    cancel:
      //prettier-ignore
      <svg width="14" height="14" viewBox="0 0 25 25" fill="none" >
        <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="white" stroke="currentColor"/>
        <path d="M16.25 8.75L8.75 16.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.75 8.75L16.25 16.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    copy_doc:
      //prettier-ignore
      <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
        <path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.6 2H15.6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    show:
    //prettier-ignore 
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none" >
    <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C13.98 8.42004 15.58 10.02 15.58 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    hide:
    //prettier-ignore 
    <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
      <path d="M14.53 9.47004L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C12.99 8.42004 13.88 8.82004 14.53 9.47004Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.42004 19.5301C9.56004 20.0101 10.77 20.2701 12 20.2701C15.53 20.2701 18.82 18.1901 21.11 14.5901C22.01 13.1801 22.01 10.8101 21.11 9.40005C20.78 8.88005 20.42 8.39005 20.05 7.93005" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.47 14.53L2 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22 2L14.53 9.47" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,

    options:
      //prettier-ignore
      <svg width="50%" viewBox="0 0 20 20" fill="none">
        <g opacity="0.5">
          <path d="M4.16667 8.73837C3.25 8.73837 2.5 9.48837 2.5 10.405C2.5 11.3217 3.25 12.0717 4.16667 12.0717C5.08333 12.0717 5.83333 11.3217 5.83333 10.405C5.83333 9.48837 5.08333 8.73837 4.16667 8.73837Z" fill="currentColor" />
          <path d="M15.8337 8.73837C14.917 8.73837 14.167 9.48837 14.167 10.405C14.167 11.3217 14.917 12.0717 15.8337 12.0717C16.7503 12.0717 17.5003 11.3217 17.5003 10.405C17.5003 9.48837 16.7503 8.73837 15.8337 8.73837Z" fill="currentColor" />
          <path d="M9.99967 8.73837C9.08301 8.73837 8.33301 9.48837 8.33301 10.405C8.33301 11.3217 9.08301 12.0717 9.99967 12.0717C10.9163 12.0717 11.6663 11.3217 11.6663 10.405C11.6663 9.48837 10.9163 8.73837 9.99967 8.73837Z" fill="currentColor" />
        </g>
      </svg>,
    stack:
      //prettier-ignore
      <svg width="50%" viewBox="0 0 24 24" fill="none">
        <path d="M13.01 2.92007L18.91 5.54007C20.61 6.29007 20.61 7.53007 18.91 8.28007L13.01 10.9001C12.34 11.2001 11.24 11.2001 10.57 10.9001L4.67 8.28007C2.97 7.53007 2.97 6.29007 4.67 5.54007L10.57 2.92007C11.24 2.62007 12.34 2.62007 13.01 2.92007Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    add_circle:
      //prettier-ignore
      <svg width="50%" viewBox="0 0 24 24" fill="none">
        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 12H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 16V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
    dollar_circle:
      //prettier-ignore
      <svg width="50%" viewBox="0 0 24 24" fill="none">
        <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 6V18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,
  };

  return icons[icon];
};

export default RoundActionButton;
