// import "unfetch/polyfill";
// import axios from 'axios'

import { removeUndefinedValues } from "@/assets/js/utils/utils";

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
// const BASE_URL = "https://api.catlog.shop"

async function client(
  endpoint: string,
  method: string,
  { data, headers: customHeaders, ...customConfig }: any = {},
  tokenSource: "local" | "session",
  baseUrl: string = BASE_URL
) {
  const localToken = localStorage === undefined ? null : localStorage.token;
  const sessionToken = sessionStorage === undefined ? null : sessionStorage.token;
  const token = tokenSource === "local" ? localToken : sessionToken;

  const headers = removeUndefinedValues({
    Authorization: token ? `Bearer ${token}` : undefined,
    "Content-Type": data ? "application/json" : undefined,
    ...customHeaders,
  });

  const config = {
    method,
    body: data ? JSON.stringify(data) : undefined,
    headers,
    ...customConfig,
  };

  return window.fetch(`${baseUrl}/${endpoint}`, config).then(async (response) => {
    const data = response.status === 204 ? {} : await response.json();
    if (response.status === 401 && token) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("current-store");
      location.reload();
    }

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

interface UploadPayload {
  // name: string;
  // file: Blob;
  // filename: string;
  // endpoint: string;
  file: File | Blob;
  endpoint: string;
  type?: string;
}

function uploadClient(
  data: UploadPayload,
  progressCallback: (value: number) => void,
  eventCallback: (data: any, err: any, req: XMLHttpRequest, retry?: () => void) => void
) {
  const token = localStorage.token;
  const { file, endpoint, type } = data;

  const formdata = new FormData();
  formdata.append("file", file);

  if (type) {
    formdata.append("type", type);
  }

  const ajax = new XMLHttpRequest();
  ajax.upload.addEventListener(
    "progress",
    function (event) {
      const progress = event.loaded / event.total;
      const percent = Math.round(progress * 100);
      progressCallback(percent);
    },
    false
  );

  ajax.addEventListener(
    "load",
    (event) => {
      eventCallback(event.target, null, ajax, null);
    },
    false
  );

  ajax.addEventListener(
    "error",
    (event) => {
      eventCallback(null, event.target, ajax, uploadFun);
    },
    false
  );

  ajax.addEventListener(
    "abort",
    () =>
      eventCallback(
        null,
        {
          message: "Upload aborted",
        },
        ajax,
        uploadFun
      ),
    false
  );

  function uploadFun() {
    ajax.open("POST", `${BASE_URL}/${endpoint}`);
    ajax.setRequestHeader("Authorization", `Bearer ${token}`);
    ajax.send(formdata);
  }

  uploadFun();
}

export { client, uploadClient };
