export type User = {
  id: number;
  name: string;
};

export interface PhoneInterface {
  code: string;
  digits: string;
}

export enum COUNTRIES {
  NG = "NG",
  GH = "GH",
}

export enum FILE_TYPES {
  ITEMS = "items",
  KYC = "kyc",
  STORES = "stores",
}

export interface CountryInterface {
  name: string;
  currency: string;
  code: COUNTRIES;
  dial_code: string;
  emoji: string;
}

export type Image = {
  src: string;
  name: string;
  lastModified: number;
  file: File | Blob;
  isUploading?: boolean;
  uploadProgress?: number;
  url?: string;
  error?: boolean;
  key?: string;
  meta?: any;
  isPlaceholder?: boolean;
};

export interface Rewards {
  SIGNUP: {
    [key: string]: number;
  };
  SUBSCRIPTION: {
    [key: string]: number;
  };
  CASHOUT: {
    [key: string]: number;
  };
}

export type Phone = { code: string; digits: string };

export * from "./carts";
export * from "./products";
export * from "./stores";
export * from "./subscriptions";
export * from "./orders-customers";
export * from "./wallets";
export * from "./payments";
export * from "./chowbot";
