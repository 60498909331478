import { ChangeEvent } from "react";
import { toast } from "../../../components/ui/toast";
import { Image as ImageType } from "../../interfaces";
// import heic2any from "heic2any";

const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

interface Args {
  e: ChangeEvent;
  images: ImageType[] | ImageType;
  saveImages: (images: ImageType[] | ImageType) => void;
  maxSize?: number; //in dimensions
  maxLength?: number;
  uploadImages?: (images: ImageType[] | ImageType) => void;
}

const handleImageSelectionFromFile = async (args: Args) => {
  const { e, images, saveImages, maxSize = 1500, maxLength, uploadImages } = args;
  const input = e.target as HTMLInputElement;
  let files = Array.from(input.files);
  let currentlyResizing = 0;

  if (maxLength && Array.isArray(images) && images.length + files.length > maxLength) {
    const toPick = maxLength - images.length; //get number of images left to reach maxlength
    files = files.slice(0, toPick);
    toast.error({ message: `You can only add ${maxLength} items`, title: "Error!" });
  }

  if (files.length > 0) {
    // let pickedImages = [];
    let uniqueImages = files.length;

    const placeholderData = files.map((file) => {
      return {
        src: "",
        name: file.name,
        lastModified: file.lastModified,
        file: file,
        isUploading: false,
        uploadProgress: 0,
        url: "",
        key: generateKey(file),
      };
    });

    saveImagesAfterResize(placeholderData, uniqueImages, images, saveImages, uploadImages);

    const pickedImages: ImageType[] = await Promise.all<ImageType>(
      files.map((file, index) => {
        return new Promise(async (res, rej) => {
          let fileIsUnique;

          if (Array.isArray(images)) {
            fileIsUnique = !images.some(({ key }) => generateKey(file) === key);
          } else if (images !== null) {
            fileIsUnique = generateKey(file) !== images.key;
          } else {
            fileIsUnique = true;
          }

          if (fileIsUnique) {
            // const fileReader = new FileReader();
            const fileIsHeic = file.name.toLowerCase().includes(".heic");

            //try to convert file to jpeg if file is .heic
            if (fileIsHeic) {
              try {
                file = await convertHeicToJpeg(file);
              } catch (e) {
                //do  nothing
              }
            }

            const interval = setInterval(() => {
              if (currentlyResizing === index) {
                clearInterval(interval);

                const blobURL = URL.createObjectURL(file);

                const img = new Image();
                img.src = blobURL;

                img.addEventListener("error", () => {
                  URL.revokeObjectURL(blobURL);
                  // Handle the failure properly - come back to this important
                  console.log("Cannot load image");
                });

                img.addEventListener("load", async () => {
                  const resizedImage = await handleImageResizeOnCanvas(img, maxSize, blobURL);
                  currentlyResizing = index + 1;
                  res({
                    src: resizedImage.url,
                    name: file.name,
                    lastModified: file.lastModified,
                    file: resizedImage.blob,
                    isUploading: false,
                    uploadProgress: 0,
                    url: "",
                    key: generateKey(file),
                  });
                });
              }
            }, 100);

            // res({ src: "", name: file.name, lastModified: file.lastModified, file: file, isUploading: false, uploadProgress: 0, url: "" })
          } else {
            uniqueImages -= 1;
            toast.error({
              title: "Select a different image",
              message: `${file.name} has been selected previously`,
            });

            res(null);
          }
        });
      })
    );

    saveImagesAfterResize(
      pickedImages.filter((image) => image !== null),
      uniqueImages,
      images,
      saveImages,
      uploadImages
    );
    input.value = "";
  }
};

function calculateSize(img: HTMLImageElement, maxSize: number) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxSize) {
      height = Math.round((height * maxSize) / width);
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width = Math.round((width * maxSize) / height);
      height = maxSize;
    }
  }
  return [width, height];
}

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}

export async function handleImageResizeOnCanvas(
  img: HTMLImageElement,
  maxSize: number,
  blobURL: string,
  getDataUrl: boolean = false
) {
  const [newWidth, newHeight] = calculateSize(img, maxSize);
  const canvas = document.createElement("canvas");
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, newWidth, newHeight);

  const blob: Blob = await new Promise(function (resolve, reject) {
    canvas.toBlob(
      (blob) => {
        //revoke old blob url
        URL.revokeObjectURL(blobURL);
        resolve(blob);
      },
      MIME_TYPE,
      QUALITY
    );
  });

  const dataUrl = canvas.toDataURL(MIME_TYPE, QUALITY);
  const newBlobUrl = URL.createObjectURL(blob as Blob);

  return { blob, url: newBlobUrl, dataUrl };
}

function saveImagesAfterResize(
  pickedImages: ImageType[],
  uniqueImages: number,
  images: ImageType | ImageType[],
  saveImages: (images: ImageType[]) => void,
  uploadImages?: (images: ImageType[] | ImageType) => void
) {
  let finalImages;

  if (Array.isArray(images)) {
    finalImages = [...images, ...pickedImages];
  } else {
    finalImages = pickedImages[0];
  }

  saveImages(finalImages);
  if (uploadImages && pickedImages[0].src) {
    uploadImages(finalImages);
  }
}

export async function convertHeicToJpeg(file: File) {
  const heic2any = (await import("heic2any")).default;

  let convertedPNG: Blob;
  try {
    convertedPNG = (await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.5,
    })) as Blob;

    file = new File([convertedPNG], file.name);

    return Promise.resolve(file);
  } catch (e) {
    return Promise.reject(e);
  }
}

export const generateKey = (file: File) => file.name + "-" + file.lastModified;

export default handleImageSelectionFromFile;
