import { CountryInterface } from ".";

export enum PLAN_TYPE {
  BASIC = "BASIC",
  BUSINESS_PLUS = "BUSINESS_PLUS",
  STARTER = "STARTER",
  KITCHEN = "KITCHEN",
}

export interface PaymentMethod {
  id: string;
  code: string;
  type: string;
  total_amount: number;
  percent: number;
}

export interface Plan {
  name: string;
  description: { title: string; features: string[] }[];
  interval: number;
  amount: number;
  actual_amount?: number;
  type: PLAN_TYPE;
  id: string;
  interval_text: string;
  methods?: PaymentMethod[];
  country?: CountryInterface | string;
  currency?: string;
  hook?: string;
  options?: any;
  discount?: number;
  plan_option_id?: string;
  chowbot_tokens?: number;
  price_per_token?: number;
  is_paid_plan: boolean;
  created_at: string;
  updated_at: string;
}

export interface SubscriptionInterface {
  next_payment_date: Date;
  status: string;
  last_payment_date: Date;
  id: string;
  is_free: boolean;
  payments: string[];
  plan: Plan;
  owner: string;
  created_at: Date;
  updated_at: Date;
  v: number;
  last_payment_reference: string;
  first_fail_charge: Date;
  last_payment: {
    payment_method_type: string;
    success: Date;
    bank: string;
    channel: string;
    status: string;
    id: string;
    plan: string;
    owner: string;
    amount: number;
    reference: string;
    created_at: Date;
    updated_at: Date;
    v: number;
    subscription: string;
  };
}

export interface SubscriptionMethod {
  id: string;
  code: string;
  type: string;
  total_amount: number;
  percent: number;
}

export interface SubscriptionPlan {
  description: string[];
  id: string;
  name: string;
  amount: number;
  interval: number;
  interval_text: string;
  type: string;
  methods: SubscriptionMethod[];
  created_at: Date;
  updated_at: Date;
  v: number;
  country: string;
}

export interface PlanOption {
  amount: number;
  interval: number;
  interval_text?: string;
  id: string;
  discount?: number;
}

export interface TokenPurchaseHistory {
  _id: string;
  id: string;
  payment_id: string;
  tokens_bought: number;
  method: string;
  balance_at_purchase: {
    tokens_bought: number;
    tokens_balance: number;
    extra_tokens: number;
    credit_limit: number;
  };
  price_per_token: number;
  owner: string;
  branch?: string;
  store?: string;
  subscription?: string;
  created_at?: string;
}

export interface TokenUsageHistory {
  date: string;
  tokens_used: number;
}
