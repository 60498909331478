import HotToast from "react-hot-toast";
import Notification, { NotificationProps } from "./notification";

type PromiseFunc = () => Promise<any>;

export interface ToastWithPromiseOptions {
  loading: NotificationProps;
  success: NotificationProps;
  error: NotificationProps;
}

const toast = {
  error: (props: NotificationProps) =>
    HotToast((t) => <Notification {...{ ...props, type: "error" }} id={t.id} />, {
      ...defaultToastOptions,
      duration: 10000,
    }),

  success: (props: NotificationProps) =>
    HotToast((t) => <Notification {...{ ...props, type: "success" }} id={t.id} />, {
      ...defaultToastOptions,
      duration: 10000,
    }),

  loading: (props: NotificationProps) =>
    HotToast((t) => <Notification {...{ ...props, type: "loading" }} id={t.id} />, {
      ...defaultToastOptions,
      duration: 10000,
    }),

  promise: (promiseFunc: PromiseFunc, options: ToastWithPromiseOptions) => {
    const toast = HotToast.promise(
      promiseFunc(),
      {
        loading: <Notification {...{ ...options.loading, type: "loading" }} />,
        success: (
          <Notification
            {...{
              ...options.success,
              message: options.success.message === "" ? "Successful" : options.success.message,
              type: "success",
            }}
          />
        ),
        error: (err) => (
          <Notification
            {...{
              ...options.error,
              message: err?.message
                ? err?.message
                : options.error.message === ""
                ? "Something went wrong!"
                : options.error.message,
              type: "error",
            }}
          />
        ),
      },
      { ...defaultToastOptions, duration: options.loading.duration ?? 30000 }
    );

    return toast;
  },
};

const defaultToastOptions: any = {
  className: "max-w-lg w-full",
  style: {
    padding: 0,
    margin: "10px 15px",
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
    maxWidth: "400px",
    inset: 0,
  },
  icon: null,
  duration: 30000,
};

export { toast };
