import { ValidationError } from "yup";
import {
  CartItem,
  CountryInterface,
  Fees,
  OrderInterface,
  PaymentCoupon,
  ProductItemInterface,
  VariantForm,
  VariantItem,
  WITHDRAWAL_FEE_TYPES,
} from "../../interfaces";
import { resolvePhone } from "./functions";
import { IDeliveryItem } from "@/assets/interfaces/deliveries";
import { COMMUNITY_LINK } from "./constants";

const getDropdownOptionsFromCountries = (countries: CountryInterface[]) => {
  if (!countries) return [];
  return countries.map((c) => ({ text: `${c.name} ${c.emoji}`, value: c.code }));
};

const isoToDrateString = (dateString: string) => {
  return dateString ? new Date(dateString).toDateString() : "-";
};

const formatDateString = (date: Date, format: "default" | "ddmm" = "default") => {
  const split: string[] = date?.toDateString()?.split(" ") ?? [];
  switch (format) {
    case "default":
      split[0] += ",";
      return split.join(" ");
    case "ddmm":
      return `${split[2]} ${split[1]} `;
  }
};

const getDateRangeText = (start: Date | string, end: Date | string, format: "default" | "ddmm" = "default") => {
  if (start) {
    const startDateText = formatDateString(new Date(start), format);
    const endDateText = end ? formatDateString(new Date(end), format) : "Till deleted";
    return `${startDateText} - ${endDateText}`;
  }

  return `${formatDateString(new Date(end), format)}`;
};

const getSelectedProductText = (selectedItemsArray: string[], items: ProductItemInterface[]) => {
  const firstSelectedItemName = items.find((i) => i.id === selectedItemsArray[0])?.name;

  if (selectedItemsArray.length > 0) {
    const s = selectedItemsArray?.length === 2 ? "" : "s";
    const andOtherProducts =
      selectedItemsArray?.length > 1 ? `& ${selectedItemsArray?.length - 1} other product${s}` : "only";
    return `${firstSelectedItemName} ${andOtherProducts}`;
  }
  return "none";
};

// const getProductNameText = (items: CartItem[]) => {
//   const s = items.length > 2 ? "s" : "";
//   const andOtherItems = items.length >= 2 ? `& ${items.length - 1} other item${s}` : "";
//   return `${items[0].item.name} ${andOtherItems}`;
// };
const getDeliveryItemsText = (items: IDeliveryItem[]) => {
  const s = items.length > 2 ? "s" : "";
  const andOtherItems = items.length >= 2 ? `& ${items.length - 1} other item${s}` : "";
  return `${items[0]?.name} ${andOtherItems}`;
};
const getActualOrderItem = (item: CartItem) => item?.item ?? item?.snapshot;

const getProductNameText = (items: CartItem[]) => {
  const s = items?.length > 2 ? "s" : "";
  const andOtherItems = items?.length >= 2 ? `& ${items?.length - 1} other item${s}` : "";
  return `${getActualOrderItem(items[0])?.name} ${andOtherItems}`;
};

const getDiscountPercentage = (item: ProductItemInterface) => {
  const { price, discount_price } = item;
  const discount = discount_price ? discount_price : price;

  return Math.ceil(((price - discount) / price) * 100);
};

const getActualPrice = (item: { price: number; discount_price?: number }) => {
  return item.discount_price ? item.discount_price : item.price;
};

const getMainPrice = (item: ProductItemInterface | VariantItem) => {
  return item.price;
};

const checkIfExpired = (date?: string) => {
  if (!date) return false;

  const today = new Date().getTime();

  return today - new Date(date).getTime() > 24 * 60 * 60 * 1000;
};

const getEnumValues = (enumObj: any) => {
  return Object.keys(enumObj).filter((item) => {
    return isNaN(Number(item));
  });
};

const getProductOptionsLength = (variants: VariantForm) => {
  if (variants.type === "custom") {
    return variants?.options?.length;
  }

  const variantHasExtraOption = variants.type === "images" && variants?.options[0]?.values !== undefined;

  if (variantHasExtraOption) {
    return Array.from(new Set(variants.options.map((o) => o.image))).length;
  }

  return variants?.options?.length;
};

const calculateWithdrawalFee = (amount: number, fees: Fees["fees"]) => {
  const feeBand = fees.find((band) => toKobo(amount) >= band.floor && toKobo(amount) < band.ceil);

  const fee = !feeBand
    ? (0.25 / 100) * amount
    : feeBand?.fee_type === WITHDRAWAL_FEE_TYPES.FIXED
    ? feeBand?.fee + (feeBand?.percentage ?? 0) * toKobo(amount)
    : feeBand?.fee * toKobo(amount);

  return fee;
};
const convertImageUrlsToImages = (imageUrls: string[]) => {
  return imageUrls.map((image) => ({
    src: image,
    name: "",
    lastModified: null,
    file: null,
    isUploading: false,
    uploadProgress: 100,
    url: image,
    error: false,
    key: null,
  }));
};
const convertItemToProduct = (item: ProductItemInterface) => {
  return {
    id: item.id,
    name: item?.name,
    thumbnail: item?.thumbnail,
    images: convertImageUrlsToImages(item?.images),
    price: item?.price?.toString(),
    discount_price: item?.discount_price?.toString(),
    price_unit: item?.price_unit,
    category: item?.category?.id,
    description: item?.description,
    variants: item?.variants,
    quantity: item?.quantity,
    is_always_available: item?.is_always_available,
  };
};

const toNaira = (amount: number) => {
  return amount / 100;
};

const toKobo = (amount: number) => {
  return amount * 100;
};

const checkProductQuantities = (items: ProductItemInterface[]) => {
  let hasVariantWithoutQuantity = false;
  for (let item of items) {
    if (item.is_always_available) {
      continue;
    }

    if (item.quantity === null || item.quantity === undefined) {
      return {
        value: false,
        step: "quantity",
      };
    }

    if (item?.variants?.options.length > 0) {
      for (let variant of item.variants.options) {
        if (variant.quantity === null || variant.quantity === undefined) {
          hasVariantWithoutQuantity = true;
          break;
        }
      }
    }
  }

  if (hasVariantWithoutQuantity) {
    return {
      value: false,
      step: "variants",
    };
  }

  return {
    value: true,
    step: "completed",
  };
};

/**
 * TransformYupErrorsIntoObject
 *
 * @description Transform the useless yup error into a useable validation object
 * @param {ValidationError} errors Yup validation errors
 * @returns {Record<string, string>} Validation errors
 */
const transformYupErrorsIntoObject = (errors: ValidationError): Record<string, string> => {
  const validationErrors: Record<string, string> = {};

  errors?.inner?.forEach((error: any) => {
    if (error.path !== undefined) {
      validationErrors[error.path] = error.errors[0];
    }
  });

  return validationErrors;
};

const getWhatsappLink = (phone?: string, message: string = "") =>
  `https://api.whatsapp.com/send/?phone=${resolvePhone(phone)}&text=${message}`;

const base64ToUint8Array = (base64) => {
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

function isValidInstagramUsername(username) {
  const regex = /^(?!.*@)(?!.*\.\.)(?!.*\.$)(?!.*\.[_.-])[A-Za-z0-9_.]+$/;
  return regex.test(username);
}

function objectsAreEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function normalizeDateToIso(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}
function removeUndefinedValues(obj) {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
      result[key] = obj[key];
    }
  }
  return result;
}

const checkItemAvailability = (item: ProductItemInterface, variant?: VariantItem, variantOnly: boolean = false) => {
  let quantityAvailable;

  if (!variant) {
    quantityAvailable =
      item?.is_always_available !== undefined || item?.quantity !== undefined
        ? item?.is_always_available || item.quantity > 0
        : true;
  } else {
    quantityAvailable = variant?.is_available && (variant?.quantity !== undefined ? variant?.quantity > 0 : true);

    if (variantOnly) return quantityAvailable;
  }

  return quantityAvailable && item.available;
};
function getCommunityLink(country: CountryInterface | string) {
  if (typeof country === "string") {
    return COMMUNITY_LINK[country];
  }

  return COMMUNITY_LINK[country?.code];
}

function computePaymentCouponDiscount(totalAmount: number, coupon: PaymentCoupon) {
  let discountAmount: number;
  if (coupon.type == "percentage") {
    discountAmount = (totalAmount * coupon.percentage) / 100;
    const discountExceeded = coupon.discount_cap ? discountAmount > coupon.discount_cap : false;
    discountAmount = discountExceeded ? coupon.discount_cap : discountAmount;
  } else if (coupon.type == "fixed") {
    discountAmount = coupon.discount_amount;
  }

  return discountAmount > totalAmount ? -totalAmount : -discountAmount;
}

function csvToJson(csv) {
  // Split the input into lines
  const lines = csv.split("\n");

  // Extract headers
  const headers = lines[0].split(",");

  // Map the rest of the lines into objects
  const result = lines.slice(1).map((line) => {
    const values = line.split(",");
    return headers.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {});
  });

  return JSON.stringify(result); // Convert the JavaScript object to a JSON string
}

export {
  getDropdownOptionsFromCountries,
  getDateRangeText,
  formatDateString,
  getSelectedProductText,
  getProductNameText,
  getDiscountPercentage,
  getActualPrice,
  checkIfExpired,
  getMainPrice,
  getEnumValues,
  getProductOptionsLength,
  convertItemToProduct,
  toKobo,
  toNaira,
  transformYupErrorsIntoObject,
  checkProductQuantities,
  getWhatsappLink,
  calculateWithdrawalFee,
  base64ToUint8Array,
  isValidInstagramUsername,
  getDeliveryItemsText,
  isoToDrateString,
  objectsAreEqual,
  normalizeDateToIso,
  removeUndefinedValues,
  checkItemAvailability,
  getActualOrderItem,
  getCommunityLink,
  convertImageUrlsToImages,
  computePaymentCouponDiscount,
  csvToJson,
};
